import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsBackspaceFill } from 'react-icons/bs';

interface UploadFileModalProps {
  selectedFiles: File[];
  setSelectedFiles: (files: File[]) => void;
  onSubmitFile: () => void;
}

const UploadFileModal: React.FC<UploadFileModalProps> = ({
  selectedFiles,
  setSelectedFiles,
  onSubmitFile,
}) => {
  const [fileError, setFileError] = useState<string | null>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setSelectedFiles(files);
      setFileError(null); // Clear any previous errors on file selection
    }
  };

  const removeFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <div>
      <div style={{ border: '1px dashed #ccc', padding: '10px', textAlign: 'center' }}>
        <input
          type="file"
          multiple
          style={{ display: 'none' }}
          id="fileUploadInput"
          onChange={handleFileSelect}
        />
        <label htmlFor="fileUploadInput" style={{ cursor: 'pointer', color: '#007bff' }}>
          Drag 'n' drop files here, or <u>click to select files</u>.
        </label>
        <p style={{ marginTop: '10px' }}>
          You can upload multiple files at once. Please ensure all files are valid and under 5MB each.
        </p>
      </div>
      {fileError && <p style={{ color: 'red', marginTop: '10px' }}>{fileError}</p>}
      <h5 style={{ marginTop: '20px' }}>Selected Files:</h5>
      <ul style={{ paddingLeft: '20px', listStyleType: 'circle' }}>
        {selectedFiles.map((file, index) => (
          <li key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
            {file.name}
            <BsBackspaceFill
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
                color: 'red',
              }}
              onClick={() => removeFile(index)}
              title="Remove the selected file"
            />
          </li>
        ))}
      </ul>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button
          className="btn-custom-primary"
          onClick={onSubmitFile}
          disabled={selectedFiles.length === 0}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default UploadFileModal;